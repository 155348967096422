import { Injector, NgModule, OnInit } from '@angular/core';
import { OpenProjectPluginContext } from 'core-app/features/plugins/plugin-context';
//
//import { DateTimeEditFieldComponent } from './date-time-edit-field/date-time-edit-field.component';

export function initializeSpecializationsPlugin(injector: Injector) {
  window.OpenProject.getPluginContext().then((pluginContext: OpenProjectPluginContext) => {
    //pluginContext.services.editField.addFieldType(DateTimeEditFieldComponent, 'datetime', ['DateTime']);
  });
}

@NgModule({
  providers: [],
})

export class PluginModule {
  constructor(injector: Injector) {
    //initializeSpecializationsPlugin(injector);
  }
}
