//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { onDayCreate } from 'core-app/shared/components/datepicker/helpers/date-modal.helpers';
import { TimezoneService } from 'core-app/core/datetime/timezone.service';
import { DatePicker } from '../datepicker';
import flatpickr from 'flatpickr';
import { DayElement } from 'flatpickr/dist/types/instance';
import { populateInputsFromDataset } from '../../dataset-inputs';
import { DeviceService } from 'core-app/core/browser/device.service';
import { OpBasicSingleDatePickerComponent } from '../basic-single-date-picker/basic-single-date-picker.component';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
@Component({
  selector: 'op-basic-single-time-date-picker',
  templateUrl: './basic-single-time-date-picker.component.html',
  styleUrls: ['../styles/datepicker.modal.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OpTimeSingleDatePickerComponent),
      multi: true,
    },
  ],
})
export class OpTimeSingleDatePickerComponent extends OpBasicSingleDatePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

  localConfirmText = "Выбрать ";
  // color: #4b4e52
  iconSvg = '<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <path d="M20 7V8.2C20 9.88016 20 10.7202 19.673 11.362C19.3854 11.9265 18.9265 12.3854 18.362 12.673C17.7202 13 16.8802 13 15.2 13H4M4 13L8 9M4 13L8 17" stroke="#4b4e52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>'

  constructor(
    readonly I18n: I18nService,
    readonly timezoneService: TimezoneService,
    readonly injector: Injector,
    readonly cdRef: ChangeDetectorRef,
    readonly elementRef: ElementRef,
    readonly deviceService: DeviceService,
  ) {
    super(I18n, timezoneService, injector, cdRef, elementRef, deviceService);
    populateInputsFromDataset(this);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected initializeDatePicker() {
    this.datePickerInstance = new DatePicker(
      this.injector,
      this.id,
      this.value || '',
      {
        allowInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-dTH:i',
        mode: 'single',
        showMonths: 1,
        plugins: [confirmDatePlugin({ confirmIcon: this.iconSvg, confirmText: this.localConfirmText })],
        onReady: (_date: Date[], _datestr: string, instance: flatpickr.Instance) => {
          instance.calendarContainer.classList.add('op-datepicker-modal--flatpickr-instance');
        },
        onChange: (_: Date[], dateStr: string) => {
          this.writeValue(dateStr);
          if (dateStr.length > 0) {
            const dateString = this.timezoneService.formattedDateTimeISOString(dateStr);
            this.valueChange.emit(dateString);
            this.onTouched(dateString);
            this.onChange(dateString);
            this.writeValue(dateString);
          }
          this.cdRef.detectChanges();
        },
        onClose: () => {
          this.picked.emit();
        },
        onDayCreate: async (_dObj: Date[], _dStr: string, _fp: flatpickr.Instance, dayElem: DayElement) => {
          onDayCreate(
            dayElem,
            true,
            await this.datePickerInstance?.isNonWorkingDay(dayElem.dateObj),
            !!this.minimalDate && dayElem.dateObj <= this.minimalDate,
          );
        },
      },
      this.input.nativeElement as HTMLInputElement,
    );
  }
}
